import React, { useRef, useState } from 'react';
import {
  Autocomplete,
  Button,
  Stack,
  Typography,
  TextField as MuiTextField,
  Box,
} from '@mui/material';
import { useForm, FormProvider } from 'react-hook-form';
import { Option, TextField } from 'component/base/TextField';

import { ConfirmationDialog } from 'component/base/ConfirmDialog';
import { useTranslation } from 'react-i18next';
import { components } from 'openapi-types';
import { useCreateSiteTemplate, useSearchSites } from 'api/site';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/pro-regular-svg-icons';
import AutomcompleteField from 'component/base/TextField/AutocompleteField';

type CreateSiteTemplateRequest = components['schemas']['CreateSiteTemplateRequest'];

export default function AddSiteTemplate() {
  const [showAddDialog, setShowAddDialog] = React.useState<boolean>(false);
  const { mutateAsync, isPending } = useCreateSiteTemplate();
  const methods = useForm<CreateSiteTemplateRequest>({
    defaultValues: {
      name: '',
      site_id: undefined,
      notes: '',
    },
    mode: 'all',
  });
  const { handleSubmit, reset } = methods;
  const [siteInputValue, setSiteInputValue] = useState<string>('');
  const { t } = useTranslation();
  const onSubmit = async (data: CreateSiteTemplateRequest) => {
    // need to figure out a better way to do this
    if (!data.site_id) {
      methods.setError('site_id', { type: 'required' });
    } else {
      await mutateAsync({ ...data, notes: data.notes || undefined, site_id: Number(data.site_id) });
      reset();
      setShowAddDialog(false);
    }
  };
  const { data: sitesData } = useSearchSites(siteInputValue);

  function handleClose() {
    reset();
    setShowAddDialog(false);
  }

  const sites: Option[] =
    sitesData?.data.result?.map(site => {
      return {
        label: site.label ? `${site.label} - ${site.domain}` : site.domain,
        value: String(site.id) ?? '',
      };
    }) ?? [];

  const siteId = methods.watch('site_id');

  return (
    <>
      <Button
        variant="contained"
        color="primary"
        sx={{
          height: '40px',
          whiteSpace: 'nowrap',
        }}
        onClick={() => {
          setShowAddDialog(true);
        }}
        startIcon={<FontAwesomeIcon icon={faPlus} />}
      >
        {t('create_new_type', {
          type: t('template'),
        })}
      </Button>
      <ConfirmationDialog
        action="confirm"
        maxWidth="sm"
        description={
          <FormProvider {...methods}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Stack spacing={2}>
                <Typography>{t('create_template_description')}</Typography>
                <TextField
                  name="name"
                  label={t('template_name')}
                  disabled={isPending}
                  fullWidth
                  rules={{ required: true }}
                />
                <AutomcompleteField
                  name="site_id"
                  options={sites}
                  isLoading={isPending}
                  label={t('sites')}
                  placeholder={t('select_a_site')}
                  required
                  onInputValueChange={value => setSiteInputValue(value)}
                />
                <TextField
                  name="notes"
                  multiline
                  rows="3"
                  disabled={isPending}
                  fullWidth
                  label={t('add_note')}
                />
              </Stack>
            </form>
          </FormProvider>
        }
        id="createSiteTemplateModal"
        onConfirm={handleSubmit(onSubmit)}
        onClose={handleClose}
        open={showAddDialog}
        title={
          <Stack>
            <Typography variant="h6">
              {t('create_new_type', {
                type: t('template'),
              })}
            </Typography>
            <Typography>{t('create_template_subheading')}</Typography>
          </Stack>
        }
        confirmText={String(t('create'))}
      />
    </>
  );
}
